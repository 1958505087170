import React from 'react';
import styled from 'styled-components';
import { mediaContainer } from '../../media';
import { containerWidths } from '../../styles';
import { formBackgroundHover } from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import { Button } from '../../Button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  align-content: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  ${mediaContainer};
  max-width: 1100px;
  margin: 0 auto;

  h1 {
    margin-top: 0 !important;
  }

  button {
    margin: 0em 0.6em 0.75rem 0;
    font-size: 0.9rem;

    &:first-child {
      margin-left: 0;
    }

    @media (min-width: ${containerWidths.mobile + 1}px) {
      margin: 0em 0.5em 1em 0.4em;
      font-size: 1rem;
    }
    @media (min-width: ${containerWidths.tablet + 1}px) {
      margin: 0em 0.75em 1em 0.5em;
    }
  }

  .back {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .anc {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  
  > main {
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    word-wrap: break-word;
    position: relative;

    @media (min-width: ${containerWidths.mobile}px) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    @media (min-width: ${containerWidths.tablet}px) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    
    > .specialisation {
      margin: 1.5rem 1rem;

      h1, h2, h3, h4, p {
        margin-left: 0;
        margin-right: 0;
      }

      h3 svg {
        margin-right: 0.75rem;
      }

      h3 a {
        color: #70af1a;

        &:hover {
          color: #559004;
        }
      }

      .img {
        width: 100%;
        text-align: center;
        margin: 2rem 0;
        
        img {
          width: 100%;
          max-width: 900px;
          border-radius: 4px;
        }
      }
      
      p, li {
        font-size: 1rem;
        line-height: 1.6rem;
      }
      ul {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      li {
        margin-bottom: 0.5rem;
      }
    }
  }

  section {
    width: 100%;
    border-top: 1px solid ${formBackgroundHover};
    margin: 1.5rem 0 1rem 0;
  }
`;

export const WhatIs: React.FC = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://cmpconstruct.com/what-is-a-construction-management-plan" />
        <meta name="title" content="What is a Construction Management Plan?" />
        <meta
          name="description"
          content="Find out what a construction management plan is, why it is important to your construction project and how CMPConstruct can assist you."
        />
        <title>What is a Construction Management Plan | CMP Construct</title>
      </Helmet>
      <Wrapper>
        <main>
          <p>
            <Link to="/">
              <Button label="< Back Home" />
            </Link>
          </p>
          <h1 className="title">What is a Construction Management Plan?</h1>
          <h2 className="subtitle">
            A Construction Management Plan (commonly referred to as a CMP) is a plan that outlines the proposed building works to be undertaken and how the constructor intends to manage the project to minimise the impact on the local residents during the works. Such plans are becoming an increasingly more commonplace requirement of most London councils and councils in major cities across the UK.
          </h2>
          <section />
          <div className="specialisation">
            <h3>
              Councils often require a CMP to be submitted as part of the planning application process for new developments or as a Planning Approval Condition, which needs a CMP to be in place before work on-site can commence.
            </h3>

            <h3>
              With the increased number of developments and projects in London, construction plans have become more imperative than before. The requirement of a CMP is not just limited to large scale projects and developments but is now a crucial requirement for any project that is likely to impact the local neighbourhood during the works. This can range from a simple basement extension taking place on an existing single residence through to major urban regeneration projects.
            </h3>

            <h3>
              A typical CMP provides, as a minimum, an overview of the construction works and the likely impact of the works on the surrounding spaces taking into account phasing, logistics and traffic management proposals alongside the management of health, safety and environmental issues both in and around the development.
            </h3>

            <div className="img">
              <img src={require('../../img/cmp1.webp')} alt="CMP Construct Logistics Plan" />
            </div>

            <h3>
              A CMP can have many facets however and looks to cover all variables of the project, that might not be immediately obvious but are required to be addressed by the local council authority. Such supporting activities could be a noise survey or the management of write-offs that weren’t required in the construction project. A construction management plan is more than just a basic plan, it is key to all areas of any project.
            </h3>

            <h3>
              It is our belief that the best plans are the plans that are created with the methodology of how the building is to be constructed being fully understood.
            </h3>

            <h3>
              This means it is important to visualise the future structure or building and its surrounding environment and developing a suitable methodology and sequencing that is sympathetic to the site location. This requires the input into the development of the plan of experienced construction professionals who fully understand the build process and selecting the methodology which is most appropriate for the project and its surroundings.
            </h3>

            <h3>
              The core intention of the Construction Management Plan is to connect all the ‘dots’ on the project and ensure that the project is delivered on time and in budget with minimal disruption. This is why it is of huge importance to have a plan in place at the very beginning of the project. If not addressed you may find yourself trying to resolve issues further down the line, which can incur significant additional costs and delays to the project.
            </h3>

            <h3>
              The complexity of a construction management plan is usually dictated by the size and nature of the building project, especially if it is required to include demolition and/or a basement construction.
            </h3>

            <h3>Commonly addressed items include:</h3>
            <ul>
              <li>Public safety and site security</li>
              <li>Site operating hours</li>
              <li>Construction methodology and programme</li>
              <li>Controls to be put in place to limit noise and vibration</li>
              <li>Proper management of air, dust, stormwater and site drainage/sediment</li>
              <li>Site waste management and materials re-use</li>
              <li>Traffic management including access routes to and from the site (including a swept path analysis)</li>
              <li>Site specific details which require detailed assessment e.g interface with adjacent owners</li>
            </ul>

            <h3>
              It is important to keep in mind that all these elements are addressed in a CMP, but depending on the client and project requirements they will be adapted accordingly. We ensure the Construction Management Plan suits every client's needs for each individual development.
            </h3>

            <div className="img">
              <img src={require('../../img/cmp2.webp')} alt="CMP Construct Demolition Plan" />
            </div>

            <h3>
              Construction projects are often complex matters with many variables. Getting a plan in harmony with clear objectives, whilst also satisfying client requirements, is often an iterative process of comparison and adjustment. We develop an initial plan for each project and review that with our clients to ensure the final plan is refined to complement the proposed project delivery, whilst providing minimal impact on the surrounding community.
            </h3>

            <h3>
              We are often asked, ‘How long does a Construction Management Plan take?’ - this is driven by the size and complexity of the project itself. The majority of the Construction Management Plans we do take between 7-10 working days from the provision of project information to delivery of the first draft for client review.
            </h3>

            <h3>
              In the scenario, the project is a more complex one of our team will work alongside your project team to fully ascertain the requirements to provide an accurate estimation.
            </h3>

            <h3>
              If you would like to understand more about the services we offer or need a fee proposal for developing a construction management plan contact CMPConstruct for a quotation today.
            </h3>

            <section />
            <h3>
              <Link to="/contact">
                <FontAwesomeIcon icon={faEnvelope as IconProp} />
                Get in touch with us today
              </Link>
            </h3>
          </div>
        </main>
      </Wrapper>
    </>
  );
}

export default WhatIs;