import React from 'react';
import styled from 'styled-components';
import { mediaContainer } from '../../media';
import { containerWidths } from '../../styles';
import { formBackgroundHover } from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet";
import { Button } from '../../Button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';

const SEO = () => (
  <Helmet>
    <link rel="canonical" href="https://cmpconstruct.com/about" />
    <meta name="title" content="About CMP Construct" />
    <meta name="description" content="If you are looking for an experienced, knowledgeable and flexible team for developing your Construction Management Plan, you are at the right place. At CMPConstruct we value results and effectiveness. Therefore, if you are opting for a CMP, your Plan will be in the right hands." />
    <title>About | CMP Construct</title>
  </Helmet>
);

const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  align-content: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  ${mediaContainer};
  max-width: 1100px;
  margin: 0 auto;

  h1 {
    margin-top: 0 !important;
    color: #14456D;
    font-size: 2.5rem;
  }

  h2 {
    color: #14456D;
    font-size: 1.2rem;
    line-height: 1.6;
    font-weight: normal;
  }

  h3 {
    color: #14456D;
    font-size: 1.4rem;
    margin: 2rem 0 1rem 0;
    font-weight: 600;
  }

  h4 {
    color: #14456D;
    font-size: 1.2rem;
    margin: 1.5rem 0 0.75rem 0;
    font-weight: 500;
  }

  p {
    color: #2C3E50;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.6rem;
  }

  button {
    margin: 0em 0.6em 0.75rem 0;
    font-size: 0.9rem;

    &:first-child {
      margin-left: 0;
    }

    @media(min-width: ${containerWidths.mobile + 1}px) {
      margin: 0em 0.5em 1em 0.4em;
      font-size: 1rem;
    }
    @media(min-width: ${containerWidths.tablet + 1}px) {
      margin: 0em 0.75em 1em 0.5em;
    }
  }

  .back {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .anc {
    position: absolute;
    top: 0px;
    left: 0px;
  }
 
  > main {
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    word-wrap: break-word;
    position: relative;

    @media(min-width: ${containerWidths.mobile}px) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    @media(min-width: ${containerWidths.tablet}px) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    
    > .specialisation {
      margin: 1.5rem 1rem;

      h1, h2, h3, h4, p {
        margin-left: 0;
        margin-right: 0;
      }

      h3 svg {
        margin-right: 0.75rem;
      }

      h3 a {
        color: #70af1a;
        text-decoration: none;

        &:hover {
          color: #559004;
        }
      }

      .img {
        width: 100%;
        text-align: center;
        margin: 2rem 0;
        
        img {
          width: 100%;
          max-width: 900px;
          border-radius: 4px;
        }
      }
    }
  }

  section {
    width: 100%;
    border-top: 1px solid ${formBackgroundHover};
    margin: 1.5rem 0 1rem 0;
  }
`;

export const About = () => {
  return (
    <>
      <SEO />
      <Wrapper>
        <main>
          <p>
            <Link to="/">
              <Button label="< Back Home" />
            </Link>
          </p>
          <h1 className="title">About CMPConstruct</h1>
          <h2 className="subtitle">
            If you are looking for an experienced, knowledgeable and flexible team for developing your Construction Management Plan, you are at the right place. At CMPConstruct we value results and effectiveness. Therefore, if you are opting for a CMP, your Plan will be in the right hands.
          </h2>
          <section />
          <div className="specialisation">
            <h3>Project Range</h3>
            <p>
              Our extensive knowledge allows us to cater for many different types of plans. From small projects such as basement extensions for single dwellings to bigger projects such as large-scale multi-million-pound developments.
            </p>

            <h3>Specialised Development</h3>
            <p>
              We are specialised in in-house development, where we thoroughly analyse every individual project we have and create a bespoke CMP solution for your needs. In addition to our construction management plans, we can organise supporting activities such as noise surveys or site logistics if required.
            </p>

            <h3>Planning Requirements</h3>
            <p>
              Construction Management Plans have become mandatory if you plan to build anything in the area of London and in many other UK cities. As such a CMP is a required document by councils for most developments and plays a crucial part in the Planning Approval process needed to begin construction.
            </p>

            <h3>Our Process</h3>
            <p>
              We create CMPs in line with all guidelines issued by the relevant borough council to ensure smooth pre-construction documentation. Usually, we need between 7-10 working days for a simple CMP, sufficient for most projects. For more complex solutions, we assess the whole project to propose an appropriate solution and timeframe. We strive to provide the perfect solution to ensure your project success.
            </p>

            <section />
            <h3>
              <Link to="/contact">
                <FontAwesomeIcon icon={faEnvelope as IconProp} />
                Get in touch with us today
              </Link>
            </h3>
          </div>
        </main>
      </Wrapper>
    </>
  );
}

export default About;